import {StaticImage} from "gatsby-plugin-image";
import React from "react";
import Layout from "../component/Layout/Layout";
import Hero from "../component/Hero/Hero";
import StickyBox from "../component/StickyBox/StickyBox";
import "../assets/styles/article-page.sass";
import Post from "../component/Posts/Post.jsx";
import CompareWidget from "../component/CompareWidget/CompareWidget";
import {graphql, Link} from "gatsby";
import {getImage} from "gatsby-plugin-image";
import Seo from "../component/Seo/Seo";
import config from '../../config.json';
import { renderToString } from 'react-dom/server';

const BlogList: React.FC = ({data}: any) => {
  const wpPage = data.wpPage;
  const posts = data.allWpPost.edges;

  let postsCount = config.POSTS_PER_PAGE;

  const storagePosts = data.allWpPost.edges.slice(postsCount);

  if(typeof window !== 'undefined'){
    sessionStorage.setItem('posts', JSON.stringify(storagePosts));
  }
  if(typeof document !== `undefined`) {
    document.addEventListener('scroll', function(e) {
      if(Boolean(document.getElementsByClassName('infinite-scroll').length) && typeof window !== 'undefined') {
        let scrollPosition = window.scrollY;
        var lastPost = document.querySelector('.post-box:last-child');

        if(scrollPosition > lastPost.offsetTop) {
          let postsList = JSON.parse(sessionStorage.getItem('posts'));
          let postsToShow = postsList.slice(0, postsCount);
          
          sessionStorage.setItem('posts', JSON.stringify(postsList.slice(postsCount)));

          let html = '';

          postsToShow.forEach(post => {
            let image = getImage(post.node.featuredImage.node.localFile)!;

            html += `<div class="col-12 col-lg-6 child-no-margin-top post-box">`+ renderToString(<Post post={post.node} image={image} type="blog" blogPage="true" />) +`</div>`;
          });

          document.querySelector('.infinite-scroll').innerHTML += html;
        }
      }
    });
  }

  return (
    <Layout>
      <Seo title={wpPage.seo.title} description={wpPage.seo.metaDesc} keywords={wpPage.seo.metaDesc} pathname="/blog-emerytalny/" />
      <Hero heading={`Blog emerytalny`} image={<StaticImage src="../assets/images/photo/hero-blog.jpg" alt="Opinie o IKZE - czy warto?" title="Opinie o IKZE - czy warto?" height={600} width={828} quality={90} />} paragraph={wpPage.opisStrony.opis} />
      <div className="container ">
        <div className="row">
          <div className="col-xl-8 article pb-section">
            <div className="row infinite-scroll">
              {posts.slice(0, postsCount).map((post: any) => {
                const image = getImage(post.node.featuredImage.node.localFile);
                return (
                  <div className="col-12 col-lg-6 child-no-margin-top post-box" key={post.node.id}>
                    <Post post={post.node} image={image} type="blog" blogPage="true" />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-xl-4">
            <StickyBox>
              <CompareWidget isNarrow={true} />
            </StickyBox>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BlogList;

export const pageQuery = graphql`
  {
    allWpPost(filter: {categories: {nodes: {elemMatch: {slug: {eq: "blog-emerytalny"}}}}}, sort: {order: DESC, fields: dateGmt}) {
      edges {
        node {
          dateGmt(formatString: "DD MMMM YYYY", locale: "pl")
          id
          title
          excerpt
          slug
          featuredImage {
            node {
              altText
              mediaItemUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 700, height: 400)
                }
              }
            }
          }
          modifiedGmt(formatString: "DD.MM.YYYY")
          zajawkablog {
            zajawka
          }
        }
      }
    }
    wpPage(slug: {eq: "blog-emerytalny"}) {
      seo {
        metaDesc
        title
        focuskw
      }
      opisStrony {
        opis
      }
    }
  }
`;
